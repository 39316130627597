import {type ThemePalette} from '../theme/colorScheme';

const PineconeColors: Record<string, ThemePalette> = {
  LIGHT: {
    BLACK: '#0A0A0A',
    WHITE: '#FFFFFF',
    PRIMARY_MAIN: '#002BFF',
    PRIMARY_DARK: '#0A0A0A',
    TEXT_PRIMARY: '#0A0A0A',
    TEXT_SECONDARY: '#737373',
    TEXT_CONTRAST: '#FFF',
    BACKGROUND: '#FAFAFA',
    PAPER: '#FFFFFF',
    SURFACE: '#F5F5F5',
    DIVIDER: '#E5E5E5',
    SECONDARY: '#737373',
    ERROR: '#E31957',
    WARNING: '#DDB15D',
    SUCCESS: '#15B077',
    INFO: '#002BFF',
    DATA_VIZ: {
      MISSING_DATA: 'rgba(114, 120, 141, 0.2)',
      DARK_BLUE: '#0D3082',
      ORANGE: '#E36219',
      BLUE: '#3BA4EB',
      PURPLE: '#A440CE',
      AMBER: '#FFB400',
      GREEN: '#86AF80',
      TEAL: '#8BF6FF',
    },
  },
  DARK: {
    BLACK: '#0A0A0A',
    WHITE: '#FFFFFF',
    PRIMARY_MAIN: '#4DA4FA',
    PRIMARY_DARK: '#FFFFFF',
    TEXT_PRIMARY: '#FFFFFF',
    TEXT_SECONDARY: '#CCCCCC',
    TEXT_CONTRAST: '#0A0A0A',
    BACKGROUND: '#171717',
    PAPER: '#1C1C1C',
    SURFACE: '#262626',
    DIVIDER: '#404040',
    SECONDARY: '#FFFFFF',
    ERROR: '#FF1A5E',
    WARNING: '#DDB15D',
    SUCCESS: '#15B077',
    INFO: '#0080FF',
    DATA_VIZ: {
      MISSING_DATA: 'rgba(114, 120, 141, 0.2)',
      DARK_BLUE: '#375FBB',
      ORANGE: '#E36219',
      BLUE: '#3087C1',
      PURPLE: '#A440CE',
      AMBER: '#FFB400',
      GREEN: '#86AF80',
      TEAL: '#8BF6FF',
    },
  },
};

export default PineconeColors;
