/* eslint-disable no-barrel-files/no-barrel-files */
// eslint-disable-next-line import/no-duplicates
import '@mui/material/styles';
import {type LinkProps} from '@mui/material/Link';
// eslint-disable-next-line import/no-duplicates
import {createTheme} from '@mui/material/styles';
import {PineconeColors} from '../colors';
import AdminTheme from './AdminTheme';
import PineconeShadows from './PineconeShadows';
import {type DataVisPalette, getColorScheme} from './colorScheme';

declare module '@mui/material/styles' {
  export interface TypeBackground {
    default: string;
    paper: string;
    surface: string;
  }

  export interface PaletteOptions {
    dataVis: DataVisPalette;
  }

  export interface Palette {
    dataVis: DataVisPalette;
  }
}

const createConsoleColorScheme = (
  colorScheme: 'light' | 'dark',
  LinkComponent?: React.ComponentType<LinkProps>,
) =>
  colorScheme === 'light'
    ? getColorScheme('light', PineconeColors.LIGHT, PineconeShadows.LIGHT, LinkComponent)
    : getColorScheme('dark', PineconeColors.DARK, PineconeShadows.DARK, LinkComponent);

const createConsoleTheme = (LinkComponent?: React.ComponentType<LinkProps>) =>
  createTheme({
    defaultColorScheme: 'light',
    cssVariables: {
      colorSchemeSelector: 'class',
      cssVarPrefix: 'theme',
    },
    colorSchemes: {
      light: createConsoleColorScheme('light', LinkComponent),
      dark: createConsoleColorScheme('dark', LinkComponent),
    },
  });

export {
  createConsoleTheme,
  createConsoleColorScheme,
  AdminTheme,
  PineconeShadows,
  type DataVisPalette,
};

export {getAppThemeModeFromCookie, setAppThemeModeCookie} from './appThemeMode/cookies';
export {APP_THEME_MODES, DEFAULT_APP_THEME_MODE, type AppThemeMode} from './appThemeMode/constants';
export {useAppTheme, useAppThemeCookie} from './appThemeMode/hooks';
