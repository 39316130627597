import {
  APP_THEME_COOKIE_KEY,
  APP_THEME_MODES,
  type AppThemeMode,
  DEFAULT_APP_THEME_MODE,
  THEME_COOKIE_MAX_AGE,
} from './constants';

export function getCookie<T>(key: string) {
  let cookie = null;

  try {
    cookie = (document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`)?.pop() as T) || null;
  } catch {
    // ignore
  }

  return cookie;
}

export function setCookie(key: string, value: string, maxAge: number, global: boolean = false) {
  const domain = global ? '.pinecone.io' : window.location.hostname;

  try {
    document.cookie = `${key}=${value}; path=/; Domain=${domain};max-age=${maxAge.toString()};`;
  } catch (error) {
    // ignore
  }
}

export function getAppThemeModeFromCookie() {
  const mode = getCookie<AppThemeMode>(APP_THEME_COOKIE_KEY);

  if (!mode) {
    return undefined;
  }

  if (Object.values(APP_THEME_MODES).includes(mode)) {
    return mode;
  }

  return DEFAULT_APP_THEME_MODE;
}

export function setAppThemeModeCookie(mode: AppThemeMode) {
  setCookie(APP_THEME_COOKIE_KEY, mode, THEME_COOKIE_MAX_AGE, true);
}
