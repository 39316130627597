import {useColorScheme} from '@mui/material/styles';
import {
  type AppThemeMode as AppThemeModeType,
  DEFAULT_APP_THEME_MODE,
  getAppThemeModeFromCookie,
  setAppThemeModeCookie,
} from '@pinecone-experience/theme';
import {useCallback, useEffect} from 'react';

interface AppThemeMode {
  theme: 'light' | 'dark';
  mode: AppThemeModeType;
  setMode: (mode: AppThemeModeType) => void;
}

export function useAppTheme(): AppThemeMode {
  const {mode, systemMode, setMode} = useColorScheme();

  const handleSetMode = useCallback(
    (newMode: AppThemeModeType) => {
      setAppThemeModeCookie(newMode);
      setMode(newMode);
    },
    // setMode is unstable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const theme = mode === 'system' ? systemMode : mode;

  return {
    theme: theme ?? 'light',
    mode: mode ?? DEFAULT_APP_THEME_MODE,
    setMode: handleSetMode,
  };
}

export function useAppThemeCookie(initialMode?: AppThemeModeType) {
  const {setMode} = useAppTheme();

  useEffect(() => {
    const setModeOnVisibilityChange = () => {
      const modeFromCookie = getAppThemeModeFromCookie();

      if (modeFromCookie && document.visibilityState === 'visible') {
        setMode(modeFromCookie);
      }
    };

    window.addEventListener('visibilitychange', setModeOnVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', setModeOnVisibilityChange);
    };
  }, [setMode]);

  useEffect(() => {
    if (initialMode) {
      setMode(initialMode);
    }
  }, [initialMode, setMode]);
}
