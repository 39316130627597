export const APP_THEME_COOKIE_KEY = 'pinecone_theme_mode';
export const THEME_COOKIE_MAX_AGE = 10 * 365 * 24 * 60 * 60; // 10 years in seconds

export const APP_THEME_MODES = {
  light: 'light',
  dark: 'dark',
  system: 'system',
} as const;

export const DEFAULT_APP_THEME_MODE = APP_THEME_MODES.system;

export type AppThemeMode = (typeof APP_THEME_MODES)[keyof typeof APP_THEME_MODES];
