import {type LinkProps} from '@mui/material/Link';
import {type PaletteOptions, alpha} from '@mui/material/styles';
import getThemeOptions from './common';

export interface DataVisPalette {
  MISSING_DATA: string;
  DARK_BLUE: string;
  ORANGE: string;
  BLUE: string;
  PURPLE: string;
  AMBER: string;
  GREEN: string;
  TEAL: string;
}

export interface ThemePalette {
  BLACK: string;
  WHITE: string;
  PRIMARY_MAIN: string;
  PRIMARY_DARK: string;
  TEXT_PRIMARY: string;
  TEXT_SECONDARY: string;
  TEXT_CONTRAST: string;
  BACKGROUND: string;
  PAPER: string;
  SURFACE: string;
  DIVIDER: string;
  SECONDARY: string;
  ERROR: string;
  WARNING: string;
  SUCCESS: string;
  INFO: string;
  DATA_VIZ: DataVisPalette;
}

export function getColorScheme(
  mode: 'light' | 'dark',
  colors: ThemePalette,
  shadows: Record<string, any>,
  LinkComponent?: React.ComponentType<LinkProps>,
) {
  const palette: PaletteOptions = {
    mode,
    common: {
      black: colors.BLACK,
      white: colors.WHITE,
    },
    primary: {
      main: colors.PRIMARY_MAIN,
      dark: colors.PRIMARY_DARK,
      contrastText: colors.TEXT_CONTRAST,
    },
    secondary: {
      main: colors.SECONDARY,
    },
    text: {
      primary: colors.TEXT_PRIMARY,
      secondary: colors.TEXT_SECONDARY,
    },
    info: {
      main: colors.INFO,
    },
    error: {
      main: colors.ERROR,
    },
    warning: {
      main: colors.WARNING,
    },
    success: {
      main: colors.SUCCESS,
    },
    background: {
      default: colors.BACKGROUND,
      paper: colors.PAPER,
      surface: colors.SURFACE,
    },
    divider: colors.DIVIDER,
    action: {
      selected: alpha(colors.PRIMARY_MAIN, 0.08),
      hover: alpha(colors.PRIMARY_MAIN, 0.04),
    },
    dataVis: colors.DATA_VIZ,
  };

  return {
    palette,
    shadows,
    ...getThemeOptions(LinkComponent),
  };
}
