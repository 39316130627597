import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import {
  DEFAULT_APP_THEME_MODE,
  createConsoleTheme,
  getAppThemeModeFromCookie,
  useAppThemeCookie,
} from '@pinecone-experience/theme';

const initialMode = getAppThemeModeFromCookie();

const styles = {
  global: {
    body: {
      background: 'transparent',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
};

function InitThemeFromCookie() {
  useAppThemeCookie(initialMode);

  return null;
}

export default function ThemeProvider({children}: {children: React.ReactNode}) {
  const theme = createConsoleTheme();

  return (
    <MuiThemeProvider theme={theme} noSsr defaultMode={initialMode || DEFAULT_APP_THEME_MODE}>
      <InitThemeFromCookie />
      <CssBaseline />
      <GlobalStyles styles={styles.global} />
      {children}
    </MuiThemeProvider>
  );
}
