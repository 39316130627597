import {type LinkProps} from '@mui/material/Link';
import {type ThemeOptions} from '@mui/material/styles';

const getThemeOptions = (LinkComponent?: React.ComponentType<LinkProps>): ThemeOptions => {
  // See Defaults Here: https://mui.com/material-ui/customization/default-theme
  return {
    typography: {
      fontSize: 14,
      fontFamily: ['Inter', 'sans-serif'].join(','),
      allVariants: {
        letterSpacing: '0.1px',
      },
      // Page Headers
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 600,
      },
      // Section Headers
      h6: {
        fontWeight: 600,
        fontSize: '16px',
      },
      // Metadata Eyebrows
      overline: {
        fontSize: '0.75rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '1px',
        lineHeight: 1.5,
      },
      // For Small Notes That Add Contextual Information
      caption: {
        fontSize: '0.75rem',
        fontWeight: 500,
      },
      // Default Paragraph Text
      body1: {
        fontSize: '0.875rem',
      },
      // Information Dense Areas
      body2: {
        fontSize: '0.75rem',
      },
      // Secondary Headlines
      subtitle1: {
        fontWeight: 500,
        fontSize: '1rem',
      },
      // Secondary Headlines for Dense Areas
      subtitle2: {
        fontWeight: 500,
        fontSize: '0.875rem',
      },
      // Button Text
      button: {
        fontWeight: 500,
        textTransform: 'none',
      },
    },
    shape: {
      borderRadius: 2,
    },
    components: {
      MuiModal: {
        styleOverrides: {
          root: {
            position: 'absolute', // Ensures Modals are positioned relative to their container.
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap',
            variants: [
              {
                props: {
                  variant: 'outlined',
                  color: 'secondary',
                },
                style: ({theme}) => ({
                  borderColor: theme.palette.divider,
                }),
              },
              {
                props: {
                  variant: 'outlined',
                  color: 'inherit',
                },
                style: ({theme}) => ({
                  borderColor: theme.palette.divider,
                }),
              },
            ],
          },
        },
      },
      MuiLink: {
        defaultProps: {
          component: LinkComponent,
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            lineHeight: '1rem',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: 400,
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: 400,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: '32px', // (theme.spacing(4))
          },
        },
      },
      MuiSwitch: {
        /* Some hacky CSS to conform the Material UI Switch style to our design. */
        styleOverrides: {
          switchBase: {
            top: '4px',
            left: '4px',
          },
          track: {
            borderRadius: '9px',
          },

          thumb: {
            width: 12,
            height: 12,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            /* Reduces the width of the container to fit the icon */
            minWidth: '24px !important',
          },
        },
      },
    },
  };
};

export default getThemeOptions;
